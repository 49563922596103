import React, {useEffect, useRef, useState} from 'react';
import {
  Container,
  Box,
  Button,
  Typography,
  CircularProgress,
  Card,
  CardMedia,
  styled,
  FormControlLabel, Checkbox, FormLabel, FormControl, RadioGroup, Radio, Stack
} from '@mui/material';
import axios from 'axios';
import Modal from 'react-modal';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  XIcon,
  WhatsappIcon,
  EmailIcon
} from 'react-share';
import {API_URL} from "./config";


import logo from './assets/images/plenitude-logo.png'
import loader from './assets/gif/loader.gif'
import downloadIcon from './assets/icons/download-icon.png'
import shareIcon from './assets/icons/share-icon.png'
import restartIcon from './assets/icons/restart-icon.png'



Modal.setAppElement('#root');

const Input = styled('input')({
  display: 'none',
});

const StyledCard = styled(Card)(({ theme }) => ({
  fontFamily: "'Montserrat', sans-serif",
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  minHeight: '40vh',
  minWidth:'80vw',
  padding: theme.spacing(2),
  cursor: 'pointer',
  border: `2px dashed white`,
  color:'white',
  backgroundColor:'transparent',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  fontFamily: "'Montserrat', sans-serif",
  width:'fit-content',
  position: 'relative',
  textAlign: 'center',
  padding: '5px',
  paddingRight: '10px',
  paddingLeft: '10px',
  cursor: 'pointer',
  fontWeight: 'bold',
  fontSize: '18px',
  borderRadius: '30px',
  backgroundColor: 'white',
  color: '#009E62',
  '&:disabled': {
    backgroundColor: 'white',
    border:'none'
  },
}));


const IconStyledButton = styled(Button)(({ theme }) => ({
  padding: 0,  // Remove all padding
  cursor: 'pointer',
  fontWeight: 'bold',
  fontSize: '18px',
  borderRadius: '50%',
  backgroundColor: 'white',
  height: '60px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',  // Ensure no content overflows
  border: 'none',  // Remove border if any
  boxSizing: 'border-box',  // Ensure the box model is correct
  '&:hover': {
    backgroundColor:'white'
  }
}));

const ModalStyledButton = styled(Button)(({ theme }) => ({
  fontFamily: "'Montserrat', sans-serif",
  textAlign: 'center',
  padding: '5px',
  paddingRight:'10px',
  paddingLeft:'10px',
  cursor: 'pointer',
  fontWeight:'bold',
  fontSize:'18px',
  borderRadius:'20px',
  color:'white',
  backgroundColor:'#009E62',
  '&:hover': {
    backgroundColor:'white'
  }
}));

const StyleButton = styled(Button)(({ theme }) => ({
  fontFamily: "'Montserrat', sans-serif",
  color:"#009E62",
  textAlign: 'center',
  fontSize:'12px',
  backgroundColor:'#fff',
  borderRadius:'20px',
  fontWeight:'bold',
  '&:hover': {
    backgroundColor:'#f8cb06'
  }
}));

const Overlay = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection:'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  zIndex: theme.zIndex.modal,
}));

const StyledOverlay = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection:'column',
  justifyContent: 'center',
  alignItems: 'left',
  background:'linear-gradient(210deg, #fccc02 0%, #009E62 35%)',
  zIndex: theme.zIndex.modal,
}));

function App() {
  const [image, setImage] = useState(null);
  const [processedImage, setProcessedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dots, setDots] = useState('');

  const [style, setStyle] = useState('realistico');

  const [conditionAccepted, setConditionAccepted] = useState(false);
  const [ageConfirm, setAgeConfirm] = useState(false);
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [trattamentoChecked, setTrattamentoChecked] = useState('');
  const [trattamento2Checked, setTrattamento2Checked] = useState(false);

  const handleChangeTrattamento = (event) => {
    setTrattamentoChecked(event.target.value);
  };

  // LOADING... DOTS Animation
  useEffect(() => {
    const dotCycle = ['.', '..', '...'];
    let currentStep = 0;

    const interval = setInterval(() => {
      setDots(dotCycle[currentStep]);
      currentStep = (currentStep + 1) % dotCycle.length;
    }, 500); // Change text every 500ms

    return () => clearInterval(interval);
  }, []);

  const handleImageUpload = (event) => {
    const [f] = event.target.files
    setImage(f);
    setProcessedImage(null);
    event.target.value = '';
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('file', image);
    setLoading(true);
    try {
      const response = await axios.post(`${API_URL}portrait/${style}`, formData);
      setProcessedImage(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadClick = async () => {
    if (processedImage) {
      try {
        const response = await fetch(processedImage, { mode: 'cors' });
        const blob = await response.blob();
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute('download', ''); // Leave the filename empty to preserve the original file type
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error downloading the image', error);
      }
    }
  };

  const handleRestart = () => {
    setProcessedImage(null)
    setImage(null)
  }

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
      <Container sx={{background:'linear-gradient(210deg, #fccc02 0%, #009E62 35%)', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh', color:'white'}}>
        {!conditionAccepted ?
            <Stack my={2} direction={"column"}>
              <Typography sx={{paddingInline:'1.8rem', paddingTop:'0'}} variant="h6" color={'white'} fontFamily="'Montserrat', sans-serif" fontWeight="bold" textAlign="left">
                Scopri con Plenitude la forza del vento e crea il tuo ritratto con l’Intelligenza Artificiale, salvalo sul tuo smartphone o condividilo sui social.
              </Typography>
              <Typography sx={{padding:'1.8rem'}} variant="body1" color={'white'} fontFamily="'Montserrat', sans-serif" textAlign="left">
                Le immagini caricate saranno elaborate dall’AI solo per il tempo necessario a fornire il servizio richiesto <br/> <br/>
                L’utilizzo dell’App è consentito solo ad utenti maggiorenni
              </Typography>
              <FormControlLabel
                  control={<Checkbox checked={ageConfirm} onChange={(e) => setAgeConfirm(e.target.checked)}/>}
                  label="Dichiaro di avere più di 18 anni"
                  sx={{
                    padding:"1.8rem",
                    paddingTop:"0",
                    paddingBottom:"0",
                    '.MuiFormControlLabel-label': {
                      fontFamily:"'Montserrat', sans-serif",
                      fontSize:"12px",
                      textAlign:"left",
                    },
                  }}
              />
              <FormControlLabel
                  control={<Checkbox checked={privacyChecked} onChange={(e) => setPrivacyChecked(e.target.checked)}/>}
                  label={<>Dichiaro di aver preso visione dell’ <a href={"/assets/informativa-privacy.pdf"} target={"_blank"} style={{color:'#f8cb06'}}>informativa privacy</a> e della <a href={"/assets/liberatoria-immagine.pdf"} target={"_blank"} style={{color:'#f8cb06'}}>dichiarazione liberatoria</a></>}
                  sx={{
                    padding:"1.8rem",
                    paddingTop:"1rem",
                    '.MuiFormControlLabel-label': {
                      fontFamily:"'Montserrat', sans-serif",
                      fontSize:"12px",
                      textAlign:"left",
                    },
                  }}
              />
              <FormControl
                  sx={{
                    paddingInline:"1.8rem",
                    paddingBottom:"0.45rem",
                    '.MuiFormControlLabel-label': {
                      fontFamily:"'Montserrat', sans-serif",
                      fontSize:"12px",
                      textAlign:"left",
                    },
                  }}
              >
                <FormLabel id="demo-controlled-radio-buttons-group"
                           sx={{
                             fontFamily:"'Montserrat', sans-serif",
                             fontSize:"12px",
                             color:'white',
                             '&.Mui-focused': {
                               color:'white',
                             }
                           }}
                >
                  Acconsento al trattamento delle foto e dei video realizzati durante l’evento secondo quanto previsto dall’informativa privacy e dalla dichiarazione liberatoria di cui sopra.
                </FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={trattamentoChecked}
                    onChange={handleChangeTrattamento}
                >
                  <FormControlLabel value="acconsento" control={<Radio />} label="Acconsento" />
                  <FormControlLabel value="nonAcconsento" control={<Radio />} label="Non acconsento" />
                </RadioGroup>
              </FormControl>
              <FormControlLabel
                  control={<Checkbox checked={trattamento2Checked} onChange={(e) => setTrattamento2Checked(e.target.checked)}/>}
                  label={<>Ai sensi degli articoli 1341 e 1342 del Codice Civile, approvo espressamente i paragrafi 3, 4, 5, 6 e 8 della dichiarazione liberatoria.</>}
                  sx={{
                    paddingInline:"1.8rem",
                    paddingBottom:"1.8rem",
                    '.MuiFormControlLabel-label': {
                      fontFamily:"'Montserrat', sans-serif",
                      fontSize:"12px",
                      textAlign:"left",
                    },
                  }}
              />
              <Box display={'flex'}>
                <StyledButton sx={{marginLeft:'auto', marginRight:'auto'}} onClick={() => setConditionAccepted(true)} disabled={!ageConfirm || !privacyChecked || trattamentoChecked !== 'acconsento' || !trattamento2Checked}>
                  PROSEGUI
                </StyledButton>
              </Box>
            </Stack>
            :
            <>
              <img src={logo} alt={'logo'} style={{marginBottom:'20px', width:'50%', padding:'2px'}}/>

              {!processedImage && <>
                <Typography variant="h5" gutterBottom textAlign={'center'} fontWeight="bold" fontFamily="'Montserrat', sans-serif">
                  SCATTA UN SELFIE
                </Typography>
                <StyledCard>
                  <label htmlFor="contained-button-file">
                    <Input accept="image/*" id="contained-button-file" type="file" capture="camera" onChange={handleImageUpload}
                           ref={fileInputRef}/>
                    {image ? (
                        <CardMedia component="img" image={URL.createObjectURL(image)} alt="Selected"/>
                    ) : (
                        <Typography variant="body1" fontFamily="'Montserrat', sans-serif">Clicca qui per scattare una foto!</Typography>
                    )}
                  </label>
                </StyledCard>
                <Box sx={{mt: 2, textAlign: 'center', display:'flex', flexDirection:'column', alignItems:'center', rowGap:'5px'}}>
                  {/*<Typography variant="caption" fontFamily="'Montserrat', sans-serif">Le immagini caricate verranno trattate esclusivamente per il tempo minimo necessario a fornire il servizio</Typography>*/}
                  {/* BOX SELEZIONE STILE */}
                  {/*<Box sx={{mt: 2, textAlign: 'center', display:'flex', flexDirection:'row', alignItems:'center', columnGap:'5px'}}>
              <Typography textAlign="left" variant="p" fontFamily="'Montserrat', sans-serif">Seleziona uno stile:</Typography>
              <StyleButton sx={style==='realistico' ? {backgroundColor:'#f8cb06'} : {} }
                           onClick={() => setStyle('realistico')}>
                Realistico
              </StyleButton>
              <StyleButton sx={style==='cartoon' ? {backgroundColor:'#f8cb06'} : {} }
                           onClick={() => setStyle('cartoon')}>
                Cartoon
              </StyleButton>
            </Box>*/}
                  <StyledButton sx={{marginTop:'2rem'}} onClick={handleSubmit} disabled={loading || !image}>
                    Conferma
                  </StyledButton>
                </Box>
              </>}
              {processedImage && <>
                <Typography variant="h6" gutterBottom textAlign={'center'} fontWeight="bold" fontFamily="'Montserrat', sans-serif" >
                  La tua foto modificata dall'AI
                </Typography>
                <Card sx={{mt: 4, minHeight: '50vh', minWidth: '80vw', backgroundColor: 'transparent', boxShadow:'none', fontFamily:"'Montserrat', sans-serif", marginTop:'0'}}>
                  <CardMedia style={{marginBottom: '10px'}} component="img" image={processedImage} alt="Processed"/>
                  {/*<Typography color="white" variant="p" gutterBottom textAlign={'center'} fontFamily="'Montserrat', sans-serif" >
              Non sei soddisfatto del risultato? <br/> <span style={{color:'#f8cb06', textDecoration:'underline'}} onClick={handleSubmit} > Genera una nuova immagine </span>
            </Typography>*/}
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    p: 2,
                    columnGap: '1rem',
                    backgroundColor: 'transparent'
                  }}>
                    {/*<StyledButton size="small" sx={{backgroundColor: '#058453'}} href={processedImage}
                            download="processed_image.jpg">
                <Download></Download>
              </StyledButton>*/}
                    <IconStyledButton onClick={openModal}>
                      <img src={shareIcon} alt={'share icon'} style={{width: '30px', height: '30px', objectFit: 'contain'}}/>
                    </IconStyledButton>
                    <IconStyledButton  onClick={handleDownloadClick}>
                      <img src={downloadIcon} alt={'share icon'} style={{width: '30px', height: '30px', objectFit: 'contain'}} />
                    </IconStyledButton>
                    <IconStyledButton onClick={handleRestart}>
                      <img src={restartIcon} alt={'share icon'} style={{width: '30px', height: '30px', objectFit: 'contain'}}/>
                    </IconStyledButton>
                  </Box>
                  <Modal
                      isOpen={isModalOpen}
                      onRequestClose={closeModal}
                      contentLabel="Share Modal"
                      style={{
                        content: {
                          top: '50%',
                          left: '50%',
                          right: 'auto',
                          bottom: 'auto',
                          marginRight: '-50%',
                          transform: 'translate(-50%, -50%)',
                          width: '300px',
                          textAlign: 'center',
                          borderRadius:'20px',
                          //backgroundColor:'rgba(142,181,73,1)',
                        },
                        overlay: {
                          backgroundColor: 'rgba(0, 0, 0, 0.7)'
                        }
                      }}
                  >
                    <Typography variant="body1" color={'black'} fontFamily="'Montserrat', sans-serif">
                      Condividi l'immagine
                    </Typography>
                    <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '10px' }}>
                      <WhatsappShareButton url={processedImage} title="">
                        <WhatsappIcon size={48} round />
                      </WhatsappShareButton>
                      <TwitterShareButton url={processedImage} title="">
                        <XIcon size={48} round />
                      </TwitterShareButton>
                      <FacebookShareButton url={processedImage} quote="">
                        <FacebookIcon size={48} round />
                      </FacebookShareButton>
                      <EmailShareButton url={processedImage} subject="" body="">
                        <EmailIcon size={48} round />
                      </EmailShareButton>
                    </div>
                    <ModalStyledButton onClick={closeModal} style={{ marginTop: '10px' }}>CHIUDI</ModalStyledButton>
                  </Modal>
                </Card>

              </>}

              {loading && (
                  <Overlay>
                    <img src={loader} alt={'loader'} style={{width:'120px'}} />
                    {/*<CircularProgress sx={{color: '#058453'}}/>*/}
                    <Typography variant="body1" color={'white'} fontFamily="'Montserrat', sans-serif">
                      Stiamo elaborando la tua foto{dots}
                    </Typography>
                  </Overlay>
              )}
            </>
        }
      </Container>
  );
}

export default App;
